import styled from 'styled-components'

import { Link as LinkDom } from 'react-router-dom'

export const Link = styled(LinkDom)`
  text-decoration: none;
  color: #828282;
  font-size: 18px;
  :hover {
    text-decoration: none;
    color: #000000;
  }
`
