import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
// import { AnimatedSwitch } from 'react-router-transition'

import Navbar from '../containers/Navbar'

function setPath(name) {
  return lazy(() => import(`../layouts/${name}`))
}

const HomeLayout = setPath('HomeLayout')
const AboutLayout = setPath('AboutLayout')
const LocationLayout = setPath('LocationLayout')
const MenuLayout = setPath('MenuLayout')
const ContactLayout = setPath('ContactLayout')
const MailLayout = setPath('MailLayout')

const routes = [
  { path: 'home', component: <HomeLayout /> },
  { path: 'about', component: <AboutLayout /> },
  { path: 'location', component: <LocationLayout /> },
  { path: 'menu', component: <MenuLayout /> },
  { path: 'contact', component: <ContactLayout /> },
  { path: 'mail', component: <MailLayout /> },
]

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routes.map(({ component, path }, index) => {
              return (
                <Route
                  key={index}
                  path={`/${path}`}
                  component={() => <Navbar>{component}</Navbar>}
                />
              )
            })}
            {/* <Route path="/mail" component={() => <MailLayout />} /> */}
            <Route render={() => <Redirect to="/home" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    )
  }
}
export default Routes
