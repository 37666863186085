import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import Routes from './routes'

class App extends Component {
  render() {
    return <Routes />
  }
}

export default App
