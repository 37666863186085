import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Image, Navbar, Nav, NavItem } from 'react-bootstrap'

import TopHeader from './TopHeader'
import Footer from './Footer'
import { Link } from './style'
const menus = [
  { label: 'หน้าแรก', path: 'home' },
  { label: 'เกี่ยวกับ', path: 'about' },
  { label: 'สาขา', path: 'location' },
  { label: 'เมนูอาหาร', path: 'menu' },
  { label: 'ติดต่อเรา', path: 'contact' },
]
class MainNavbar extends Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <div
          className="uk-card uk-card-default uk-card-body"
          style={{ zIndex: 1, backgroundColor: '#fff', padding: 0 }}
          uk-sticky="bottom: #offset"
        >
          <div
            style={{
              bottom: 20,
              right: 30,
              position: 'fixed',
              zIndex: 2,
              width: 200,
            }}
          >
            <div className="uk-animation-toggle">
              <div className="uk-animation-shake">
                <p className="uk-text-center">
                  <Link to="/mail">
                    <button className="btn booking_bottom">
                      Making a Reservation{' '}
                    </button>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <TopHeader />
          <div>
            <Container>
              <Navbar expand="md">
                <Navbar.Brand href="#home">
                  <a
                    href="#brand"
                    style={{ padding: 0, marginTop: 5, marginLeft: 20 }}
                  >
                    <Image
                      style={{ margin: 0, width: 80, height: 90 }}
                      src={require('../../assets/images/logo.png')}
                      rounded
                    />
                  </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                  <Nav>
                    {menus.map(({ label, path }, index) => {
                      return (
                        <NavItem
                          key={index}
                          style={{
                            padding: '10px 15px',
                            fontWeight: 500,
                          }}
                        >
                          <Link
                            to={`/${path}`}
                            activeStyle={{ background: 'cyan' }}
                          >
                            {label}
                          </Link>
                        </NavItem>
                      )
                    })}
                    <Navbar.Text
                      style={{ padding: 5, marginTop: 0, marginBottom: 0 }}
                    >
                      <a href="tel: 022585308" style={{ color: '#828282' }}>
                        <Image
                          style={{ marginBottom: 15, width: 20, height: 20 }}
                          src={require('../../assets/images/icon_phone.png')}
                          rounded
                        ></Image>
                        02-258-5308
                      </a>
                    </Navbar.Text>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </div>
        </div>
        {children}
        <Footer />
      </div>
    )
  }
}

MainNavbar.propTypes = {
  children: PropTypes.node.isRequired,
}
export default MainNavbar
