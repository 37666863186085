import React, { Component } from 'react'
import { Image } from 'react-bootstrap'

class Footer extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            backgroundImage: `url(${require(`../../assets/images/bg_ft.jpg`)})`,
            paddingTop: 200,
            paddingBottom: 30,
            width: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="uk-text-center" style={{ marginTop: 30 }}>
            <Image src={require('../../assets/images/logo_ft.png')} />
            <div className="uk-text-center" style={{ marginTop: 30 }}>
              <p style={{ color: '#FFFFFF' }}>
                <span
                  uk-icon="icon:home ; ratio: 0.7"
                  style={{ color: '#FFFFFF', marginRight: 5, marginLeft: 5 }}
                ></span>
                25 Sukhumvit Soi 34, Bangkok
              </p>
              <p style={{ color: '#FFFFFF' }}>
                <span
                  uk-icon="icon:receiver ; ratio: 0.7"
                  style={{ color: '#FFFFFF', marginRight: 5, marginLeft: 5 }}
                ></span>
                02-258-5308-9
              </p>
              <p style={{ color: '#FFFFFF' }}>
                <span
                  uk-icon="icon:clock ; ratio: 0.7"
                  style={{ color: '#FFFFFF', marginRight: 5, marginLeft: 5 }}
                ></span>
                Monday – Sunday 11 AM – 10 PM
              </p>
            </div>
            <div className="uk-text-center" style={{ marginTop: 30 }}>
              <span
                uk-icon="icon:facebook ; ratio: 1.5"
                style={{ color: '#FFFFFF', marginRight: 5, marginLeft: 5 }}
              ></span>
              <span
                uk-icon="icon:instagram ; ratio: 1.5"
                style={{ color: '#FFFFFF', marginRight: 5, marginLeft: 5 }}
              ></span>
              <span
                uk-icon="icon:twitter ; ratio: 1.5"
                style={{ color: '#FFFFFF', marginRight: 5, marginLeft: 5 }}
              ></span>
            </div>
          </div>
        </div>
        <div
          className="uk-text-center"
          style={{
            backgroundColor: '#222222',
            paddingTop: 20,
            paddingBottom: 1,
          }}
        >
          <p style={{ color: '#FFFFFF', fontSize: 13 }}>
            © 2018, TALING PLING RESTAURANT
          </p>
        </div>
      </div>
    )
  }
}

export default Footer
