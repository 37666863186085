import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

class Header extends Component {
  render() {
    return (
      <div style={{ backgroundColor: '#E41F69', height: 30 }}>
        <Container>
          {/* <a style={{ color: '#FFFFFF', fontSize: 12, fontWeight: 'bold' }}>
          ภาษาไทย
        </a>
        <a
          style={{
            color: '#FFFFFF',
            marginLeft: 2,
            fontSize: 12,
            fontWeight: 'bold',
          }}
        >
          English
        </a> */}
        </Container>
      </div>
    )
  }
}

export default Header
